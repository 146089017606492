<template>
  <div class="">
    <center-title title="汇款管理详情"></center-title>
    <el-form :inline="true" class="demo-form-inline">
      <div class="row">
        <!-- <div class="row">
          <el-form-item>
            <el-select v-model="type" placeholder="付款状态">
              <el-option label="全部" value=" "></el-option>
              <el-option label="未付款" value="1"></el-option>
              <el-option label="已付款" value="2"></el-option>
            </el-select>
          </el-form-item>
        </div>
        <el-form-item>
          <el-button type="primary" class="whtie submit" @click="getBillLists()">筛选</el-button>
        </el-form-item> -->
        <el-form-item
          v-if="parseFloat(settlement.quota_money) > 0 && (settlement.status == 1 || settlement.status == 5) && type == 1">
          <el-button type="primary" class="whtie submit" @click="uploadDialogVisible = true">上传支付凭证</el-button>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" class="whtie submit" @click="toUrl" v-if="settlement.pay_img && type == 1 && parseFloat(settlement.quota_money) > 0">查看现有支付凭证</el-button>
        </el-form-item>
      </div>
    </el-form>
    <el-dialog title="支付凭证上传" :visible.sync="uploadDialogVisible" width="30%">
      <el-upload :action="`${download}/file/formimage`" :headers="{ token: $store.getters.userInfo.token }"
        :on-success="successUpload" :on-exceed="handleExceed" :show-file-list="true" :limit="1"
        :on-remove="handleRemove">
        <el-button size="small" type="primary" class="white">点击上传</el-button>
        <!-- <button type="primary" class="white xs back" @click="$router.replace('/orderlist')">点我上传</button> -->
        <!-- <el-image class="avatar" :src="ruleForm.avatar" fit="cover" v-if="ruleForm.avatar"></el-image>
                  <i v-else class="el-icon-plus avatar-uploader-icon"></i> -->
      </el-upload>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="handleUploadPay()">提交</el-button>
      </span>
    </el-dialog>
    <el-table :data="billList" style="width: 100%">
      <el-table-column align="center" prop="settle_sn" label="结算编号" width="200">
      </el-table-column>
      <el-table-column align="center" prop="order_sn" label="订单编号" width="180">
      </el-table-column>
      <el-table-column align="center" prop="order_amount" label="订单金额" width="120">
      </el-table-column>
      <el-table-column align="center" prop="quota_amount" label="额度金额" width="120">
      </el-table-column>
      <el-table-column align="center" prop="create_time" label="对账时间" width="180">
      </el-table-column>
      <el-table-column align="center" prop="status" label="订单商品信息" width="320">
        <template slot-scope="scope">
          <div class="shop-list">
            <div class="shop-item row" v-for="(item, index) in scope.row.order_goods" :key="index">
              <el-image style="width: 80px; height: 80px" :src="item.image" fit="cover" class="mr10"></el-image>
              <div class="flex1">
                <p class=" line2 nr mb10 mt5">{{ item.goods_name }}</p>
                <p class="row-between ">
                  <make-priceformat :price="item.goods_price" :subscriptSize="13" :firstSize="16" :secondSize="16">
                  </make-priceformat>
                  <span>X{{ item.goods_num }}</span>
                </p>
              </div>
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column align="center" prop="buyer" label="下单人账号" width="160">
      </el-table-column>
      <el-table-column align="center" prop="consignee" label="收货人" width="160">
      </el-table-column>
      <el-table-column align="center" prop="mobile" label="收货手机号码" width="160">
      </el-table-column>
      <el-table-column align="center" prop="address" label="收货地址" width="200">
      </el-table-column>
    </el-table>
  </div>
</template>
<script>
import CenterTitle from "../components/CenterTitle";
export default {
  name: "",
  props: {},
  components: {
    "center-title": CenterTitle,
  },
  data() {
    return {
      type: '',
      billList: [],
      uploadDialogVisible: false,
      download: process.env.VUE_APP_DOWNLOAD_URL,
      uploadPay: '',
      settlement: {}
    };
  },
  computed: {},
  watch: {},
  methods: {
    toUrl() {
      window.open(this.settlement.pay_img, "_blank");
    },
    /**
     * @description 获取发票订单列表
     * @return void
     */
    getBillLists() {
      console.log(this.type)
      this.$api
        .getSettleOrderApi({
          settle_id: this.$route.query.id,
          type: this.type
        })
        .then((res) => {
          this.billList = res.data.settlement_record;
          this.settlement = res.data.settlement;
        });
    },
    handleRemove(file, fileList) {
      this.uploadPay = ''
    },
    successUpload(res) {
      let { code, data } = res;
      this.uploadPay = res.data.uri
    },
    handleExceed(files, fileList) {
      this.$message.warning(`当前限制上传 1 个文件`);
    },
    handleUploadPay() {
      if (!this.uploadPay) return this.$message.warning(`请上传支付凭证`);
      this.$api
        .setRmOperateApi({
          id: this.$route.query.id,
          pay_img: this.uploadPay,
          type: 5
        })
        .then((res) => {
          if (res.code == 1) {
            this.$message.success(`上传成功`);
            this.getBillLists();
            this.uploadDialogVisible = false
          }
        });
    },
  },
  created() {
    this.type = this.$route.query.type || 1
    this.getBillLists();
  },
  mounted() { },
  beforeDestroy() { },
};
</script>
<style lang='scss' scoped>
.operation {
  padding: 10px 0;

  .el-dropdown {
    font-size: 16px;
  }

  .item {
    ::v-deep .el-select {
      .el-input__inner {
        background-color: transparent;
        border: none;
        font-size: 16px;
        text-align: center;
      }

      input::-webkit-input-placeholder {
        color: $-color-normal;
      }

      input::-moz-placeholder {
        color: $-color-normal;
      }

      input:-moz-placeholder {
        color: $-color-normal;
      }

      input:-ms-input-placeholder {
        color: $-color-normal;
      }

      .el-input__icon {
        color: $-color-normal;
      }

      .el-input__suffix {
        right: 15px;
      }
    }
  }
}

.order-list {
  .order-item {
    margin-bottom: 15px;

    ::v-deep .el-checkbox__label {
      display: none;
    }

    .item-title {
      height: 48px;
      line-height: 48px;
    }

    .order-box {
      border: 1px solid $-color-center;
      border-top: none;
      align-items: flex-start;
    }

    .pro-list {
      .pro-item {
        padding: 20px 0;
        border-bottom: 1px solid $-color-center;
        border-right: 1px solid $-color-center;

        &:last-child {
          border-bottom: none;
        }

        .item-info {
          .item-img {
            width: 64px;
            height: 64px;
            flex-shrink: 0;
          }
        }
      }
    }

    .pro-right {
      padding-top: 44px;

      .operation-btn {
        width: 72px;
        height: 28px;
        border: 1px solid $-color-primary;
        border-radius: 4px;
        display: inline-block;
        color: $-color-primary;
        line-height: 26px;
      }
    }
  }
}

.shop-list {
  padding: 0 10px;
  margin: -10px 0;

  .shop-item {
    padding: 10px 0;
    border-bottom: $-solid-border;
    align-items: flex-start;

    &:last-child {
      border-bottom: none;
    }

    p {
      text-align: left;
    }
  }
}

.invoice-footer {
  button {
    width: 80px;
    height: 32px;
    border: 1px solid $-color-warn;
    border-radius: 4px;
  }
}



::v-deep .el-table {
  border: $-solid-border;
  border-bottom: none;

  th {
    background-color: $-color-center;
    font-size: 16px;
    font-weight: normal;
    color: $-color-lighter;
  }

  td {
    &:nth-child(2) {
      .cell {
        padding: 0 10px;
      }
    }

    .cell {
      padding: 10px 0px;
      line-height: 1.3;
      word-break: break-all;
    }
  }

  th,
  td {
    border-right: $-solid-border;

    &:last-child {
      border-right: none;
    }
  }
}
</style>